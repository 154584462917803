<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
          v-model="dialogCourse"
          max-width="1400px"
          scrollable
          persistent
          :retain-focus="false"
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span v-if="type_form === 'add'" class="headline"
              >Add Course</span
              >
              <span v-if="type_form === 'update'" class="headline"
              >Edit Course</span
              >
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogCourse = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="7" class="pb-0">
                  <label class="text-h6">Language</label>
                  <v-radio-group
                      v-model="language"
                      row
                      class="mb-0 mt-0 pb-0"
                      dense
                  >
                    <v-radio label="Việt Nam" value="vi"></v-radio>
                    <v-radio label="English" value="en"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="7" class="pb-0">
                  <v-text-field
                      label="Name"
                      dense
                      v-if="language === 'en'"
                      v-model="name_input.en"
                      required
                  ></v-text-field>
                  <v-text-field
                      label="Name"
                      dense
                      v-if="language === 'vi'"
                      v-model="name_input.vi"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <label class="text-h6">Description</label>
                  <Editor
                      v-if="language === 'en'"
                      v-model="description_input.en"
                  ></Editor>
                  <Editor
                      v-if="language === 'vi'"
                      v-model="description_input.vi"
                  ></Editor>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      label="Entry Level"
                      type="number"
                      v-model="entry_level_input"
                      min="0" max="9" maxlength="1" required
                  ></v-text-field>
                </v-col>
                <v-col cols="1">
                  <v-icon class="pt-6 pl-4">mdi-arrow-right-bold-outline</v-icon>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      label="End Of Course Level"
                      type="number"
                      v-model="end_of_course_level_input"
                      min="0"
                      max="9"
                      maxlength="1"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="m-0 p-0"></v-col>
                <v-col cols="6" class="d-flex pb-0 pt-0">
                  <label style="font-size: 16px" class="mt-1 p-0">Locked:</label>
                  <v-switch
                      dense
                      class="mt-0 pt-0 ml-2"
                      v-model="is_locked"
                      :label="`${is_locked.toString().toUpperCase()}`"
                  ></v-switch>
                </v-col>
                <v-col cols="" class="d-flex" v-if="is_locked === true">
                  <label style="font-size: 16px" class="mt-2 p-0">Unlock at:</label>
                  <date-picker
                      class="ml-2"
                      v-model="unlock_at_input"
                      value-type="format"
                      format="YYYY-MM-DD"
                  />
                </v-col>
                <v-col cols="12"></v-col>
                <v-col cols="6" class="d-flex pb-0 pt-0">
                  <label style="font-size: 16px" class="mt-1 p-0">Hỗ trợ Learning Assistant:</label>
                  <v-switch
                      dense
                      class="mt-0 pt-0 ml-2"
                      v-model="support_assistant"
                      :label="`${support_assistant.toString().toUpperCase()}`"
                  ></v-switch>
                </v-col>
                <v-col cols="7" class="pb-0">
                  <v-text-field
                      outlined dense min="0" max="9999" maxlength="4" type="number" required
                      v-model="total_hours_input"
                      label="Total Hours(h)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <label class="text-h6">Gắn Tag</label>
                  <multiselect
                      v-model="tag_selected"
                      tag-placeholder="Tạo tag này"
                      placeholder="Tìm kiếm hoặc tạo tag"
                      label="name"
                      track-by="id"
                      :options="tags"
                      :multiple="true"
                      :taggable="true"
                  ></multiselect>
                </v-col>
                <v-col cols="10" class="pb-0 d-flex">
                  <label style="width: 40%" class="text-h6">
                    Giới hạn số lần submit bài:
                    <span class="text-subtitle-2 red--text">
                      (để trống nếu không muốn giới hạn)
                    </span>
                  </label>
                  <v-text-field
                      outlined
                      dense
                      class="ml-4"
                      clearable
                      type="number"
                      min="0"
                      v-model="max_attempt_input"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="pb-0 d-flex">
                  <label style="width: 40%" class="text-h6">
                    Deadline chấm bài (ngày)
                    <span class="text-subtitle-2 red--text">
                      (để trống nếu không phải khóa chấm chữa)
                    </span>
                  </label>
                  <v-text-field
                      outlined
                      dense
                      class="ml-4"
                      clearable
                      type="number"
                      min="0"
                      v-model="deadline_date_marking_input"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="pb-0 d-flex">
                  <label style="width: 40%" class="text-h6">
                    Giới hạn phân tích VSR
                  </label>
                  <v-text-field
                      outlined
                      dense
                      class="ml-4"
                      clearable
                      type="number"
                      min="0"
                      v-model="limit_vsr_analysis_input"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="pb-0 d-flex">
                  <label style="width: 40%" class="text-h6">
                    Giới hạn phân tích VWR
                  </label>
                  <v-text-field
                      outlined
                      dense
                      class="ml-4"
                      clearable
                      type="number"
                      min="0"
                      v-model="limit_vwr_analysis_input"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="pt-2 pb-0">
                  <label style="font-size: 16px">Short Description</label>
                  <Editor
                      v-if="language === 'en'"
                      v-model="short_description_input.en"
                  ></Editor>
                  <Editor
                      v-if="language === 'vi'"
                      v-model="short_description_input.vi"
                  ></Editor>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <label class="text-h6">Teachers</label>
                  <v-autocomplete
                      dense
                      v-model="teacher_ids"
                      :disabled="isUpdating"
                      :items="teacher_tags"
                      filled
                      chips
                      color="blue-grey lighten-2"
                      label=""
                      item-text="name"
                      item-value="id"
                      multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click="data.select"
                          @click:close="removeTeacherTag(data.item)"
                      >
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                            v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title
                              v-html="data.item.name"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <ImageCompress :done="getFiles"></ImageCompress>
                  <v-img :src="cover_vi_input" v-if="language === 'vi'"></v-img>
                  <v-img :src="cover_vi_input" v-if="language === 'en'"></v-img>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
                class="btn btn-light-success text-uppercase mr-2"
                v-if="type_form === 'add'"
                @click="btnAddOrEditCourse(1)"
            >
              Add
            </button>
            <button
                class="btn btn-light-success text-uppercase mr-2"
                v-if="type_form === 'update'"
                @click="btnAddOrEditCourse(2)"
            >
              Update
            </button>
            <button
                class="btn text-uppercase mr-2"
                style="color: #f64e60"
                @click="dialogCourse = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>

import Swal from "sweetalert2";
import ApiService from "@/service/api.service";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "CourseForm",
  components: {
    Loading: () => import("vue-loading-overlay"),
    Editor: () => import("@/components/ckeditor5/Editor.vue"),
    ImageCompress: () => import("@/components/image/V-ImageCompress"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    type_form: {
      type: String,
      default: null,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    teacher_tags: {
      type: Array,
      default: () => [],
    },
    course: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      is_call_api: false,
      language: "vi",
      code_input: "",
      name_input: "",
      description_input: "",
      short_description_input: null,
      entry_level_input: "",
      end_of_course_level_input: "",
      type_input: "",
      fee_input: 0,
      total_hours_input: "",
      cover_vi_input: null,
      cover_en_input: null,
      cover_vi_file: null,
      cover_en_file: null,
      type_form_course: null,
      isUpdating: false,
      idCourse: null,
      optionFree: ["Free", "Paid"],
      teacher_ids: [],
      courses: [],
      is_locked: false,
      unlock_at_input: null,
      tag_selected: [],
      max_attempt_input: null,
      deadline_date_marking_input: null,
      limit_vsr_analysis_input: null,
      limit_vwr_analysis_input: null,
      support_assistant: false,
    }
  },
  computed: {
    dialogCourse: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000);
      }
    },
    dialogCourse(val) {
      if (val) {
        if (this.type_form === "add") {
          this.defaultDataCourse();
        } else {
          this.setDataForm();
        }
      }
    }
  },
  created() {
    this.defaultDataCourse();
  },
  methods: {
    canAccess(permission) {
      return this.$utils.canAccess(permission);
    },
    removeTeacherTag(item) {
      let index = this.teacher_ids.indexOf(item.id);
      if (index >= 0) this.teacher_ids.splice(index, 1);
    },
    getFiles(obj) {
      if (this.language === "vi") {
        this.cover_vi_input = obj.blob;
        this.cover_vi_file = obj.file;
      } else {
        this.cover_en_input = obj.blob;
        this.cover_en_file = obj.file;
      }
    },
    setDataForm() {
      let data = this.course;
      data = JSON.parse(JSON.stringify(data));
      this.language = "vi";
      this.teacher_ids = data.teachers.data.map((teacher) => {
        return teacher.id;
      });
      this.idCourse = data.id;
      // this.code_input = data.code;
      this.name_input = data.name;
      this.description_input = data.description;
      this.entry_level_input = data.entry_level;
      this.end_of_course_level_input = data.end_of_course_level;
      this.type_input = data.type === 1 ? "Free" : "Paid";
      this.fee_input = data.fee;
      this.total_hours_input = data.total_hours;
      this.is_locked = data.is_locked === 1;
      this.unlock_at_input = data.is_locked === 1 ? this.$options.filters.convertTimestampToDate(data.unlocked_at) : null;
      if (data.cover) {
        this.cover_en_input = data.cover.en;
        this.cover_vi_input = data.cover.vi;
      } else {
        this.cover_en_input = null;
        this.cover_vi_input = null;
      }
      if (data.short_description) {
        this.short_description_input = data.short_description;
      } else {
        this.short_description_input = {en: "", vi: "",};
      }
      this.tag_selected = data.tags.data;
      this.max_attempt_input = data.max_submission_attempt;
      this.deadline_date_marking_input = data.deadline_date_marking;
      if (data.configs.length > 0) {
        let config = data.configs[0];
        this.limit_vsr_analysis_input = config.limit_vsr_analysis;
        this.limit_vwr_analysis_input = config.limit_vwr_analysis;
        this.support_assistant = config.support_assistant == 1;
      } else {
        this.limit_vsr_analysis_input = null;
        this.limit_vwr_analysis_input = null;
        this.support_assistant = false;
      }
    },
    async btnAddOrEditCourse(type) {

      let vm = this;
      let formData = new FormData();
      // formData.append('code', this.code_input);
      formData.append("name", JSON.stringify(this.name_input));
      formData.append("description", JSON.stringify(this.description_input));
      formData.append("entry_level", this.entry_level_input);
      formData.append("end_of_course_level", this.end_of_course_level_input);

      formData.append("type", 1);
      formData.append("total_hours", this.total_hours_input);
      formData.append("teacher_ids", this.teacher_ids);
      formData.append("short_description", JSON.stringify(this.short_description_input));
      formData.append("is_locked", this.is_locked === true ? 1 : 0);
      formData.append("tag_ids", this.tag_selected.map((e) => {
            return e.id;
          })
      );
      if (this.max_attempt_input) {
        formData.append("max_submission_attempt", this.max_attempt_input);
      }
      if (this.deadline_date_marking_input) {
        formData.append("deadline_date_marking", this.deadline_date_marking_input);
      }
      if (this.unlock_at_input != null) {
        formData.append("unlocked_at", this.unlock_at_input);
      }
      if (this.cover_en_file != null) {
        // data.cover_en = this.cover_file.en;
        formData.append("cover_en", this.cover_en_file);
      }
      if (this.cover_vi_file != null) {
        // data.cover_vi = this.cover_file.vi;
        formData.append("cover_vi", this.cover_vi_file);
      }
      if (this.limit_vsr_analysis_input) {
        formData.append("limit_vsr_analysis", this.limit_vsr_analysis_input);
      }
      if (this.limit_vwr_analysis_input) {
        formData.append("limit_vwr_analysis", this.limit_vwr_analysis_input);
      }
      formData.append("support_assistant", this.support_assistant ? 1 : 0);
      this.is_call_api = true;
      if (type === 1) {
        await ApiService.post("prep-app/courses", formData)
            .then(function (res) {
              if (res.status === 201) {
                vm.is_call_api = false;
                vm.$emit("resetList");
                //vm.courses.push(data);
                Swal.fire({
                  title: "",
                  text: "The application has been successfully submitted!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                vm.dialogCourse = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              if (error.response.status === 422) {
                let data_response_error =
                    error.response.data.error.message.split(".<br />");
                vm.showError(data_response_error);
              }
            });
      } else {
        await ApiService.post("prep-app/courses/" + vm.idCourse, formData)
            .then(function (res) {
              if (res.status === 202) {
                vm.is_call_api = false;
                vm.$emit("resetList");
                //vm.courses.splice(this.idCourse, 1, data);
                Swal.fire({
                  title: "",
                  text: "The application has been successfully submitted!",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                });
                vm.dialogCourse = false;
              }
            })
            .catch(function (error) {
              vm.is_call_api = false;
              if (error.response.status === 422) {
                let data_response_error =
                    error.response.data.error.message.split(".<br />");
                vm.showError(data_response_error);
              }
            });
      }
      // }
    },
    defaultDataCourse() {
      this.language = "vi";
      this.teacher_ids = [];
      this.code_input = "";
      this.name_input = {
        en: "",
        vi: "",
      };
      this.description_input = {
        en: "",
        vi: "",
      };
      this.short_description_input = {
        en: "",
        vi: "",
      };
      this.entry_level_input = "";
      this.end_of_course_level_input = "";
      this.type_input = "";
      this.fee_input = 0;
      this.total_hours_input = "";
      // this.cover_input = {
      //   en: null,
      //   vi: null,
      // };
      this.cover_vi_input = null;
      this.cover_en_input = null;
      // this.cover_file = {
      //   en: null,
      //   vi: null,
      // };
      this.cover_vi_file = null;
      this.cover_en_file = null;
      this.is_locked = false;
      this.unlock_at_input = null;
      this.tag_selected = [];
      this.max_attempt_input = null;
      this.deadline_date_marking_input = null;
      this.limit_vsr_analysis_input = 1;
      this.limit_vwr_analysis_input = 1;
      this.support_assistant = false;
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  }
}
</script>

<style scoped>

</style>